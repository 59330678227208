<template>
  <div class="vista-escritorio">
    <cabecera />
    <cuerpo>
      <div class="row video-explicativo py-5 mt-4 mr-0">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <b-embed
                type="iframe"
                aspect="16by9"
                src="https://www.youtube.com/embed/c7hzUYkooXY"
                allowfullscreen
              ></b-embed>
            </div>
          </div>
        </div>
      </div>

      <div class="row definicion py-5 mt-4 mr-0" id="BaseDeDatos">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="text-center mb-3">
                Definición
              </h1>

              <h3>
                - Base de Datos y utilidad -
              </h3>

              <div class="texto-definicion">
                <p>
                  La <strong>base de datos</strong> se refiere al conjunto de información
                  perteneciente al <strong>Programa de Resultados Electorales Preliminares,</strong> ordenada de forma sistemática para su descarga y análisis. Es actualizada conforme al último corte. Permite realizar distintas operaciones ya que incluye datos númericos, alfanuméricos y cronológicos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row carpeta-archivos py-5 mt-4 mr-0" id="Contenido">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h1 class="text-center mb-5">
                Carpeta de archivos
              </h1>
            </div>
            <div class="col-6">
              <h3 class="mb-2">
                - Contenido -
              </h3>
              <p class="mb-5">
                Se trata de un archivo comprimido con extensión <strong>.zip,</strong> contiene:
              </p>

              <ul class="descripcion">
                <li>
                  <p>
                    <strong>
                      Gubernatura.csv
                    </strong>
                  </p>

                  <p>
                    Contiene los resultados de la votación por Acta a nivel Estatal
                  </p>

                  <p>
                    Muestra el número de votos recibidos por cada uno de los Partidos Políticos, Coaliciones, Candidaturas Independientes, Candidaturas no registradas y votos nulos.
                  </p>
                </li>

                <li>
                  <p><strong>Gubernatura_candidaturas_2021.csv</strong></p>

                  <p>
                    Describe las variables del archivo que contiene el listado de candidaturas por Partidos Políticos e Independientes que participan en la elección Estatal.
                  </p>
                </li>

                <li>
                  <p><strong>LEEME.txt</strong></p>

                  <p>
                    Incluye la descripción y codificación de cada uno de los campos que contiene la Base de datos.
                  </p>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <img src="@/assets/basededatos/carpetaarchivos.gif" class="img-fluid">
            </div>
          </div>
        </div>
      </div>

      <div class="row descargar py-5 mt-4 mr-0" id="Descargar">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h2 class="mb-5">
                - Descargar -
              </h2>

              <section class="grilla">
                <div class="contenido">
                  <h4 class="titulo">
                    1
                    <span>
                      Descarga
                    </span>
                  </h4>

                  Da clic en el botón <strong>Base de datos.</strong>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4 class="titulo">
                    2
                    <span>
                      Localiza
                    </span>
                  </h4>

                  El archivo en la carpeta de <strong>Descargas.</strong>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4 class="titulo">
                    3
                    <span>
                      Utiliza los comandos para localizar
                    </span>
                  </h4>

                  Windows: CTRL + J <br />
                  Mac: Alt + Cmd + L
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4 class="titulo">
                    4
                    <span>
                      Selecciona
                    </span>
                  </h4>

                  Da clic sobre el archivo seleccionado
                </div>
              </section>

              <img src="@/assets/basededatos/descargar.gif" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>

      <div class="row descomprimir py-5 mt-4 mr-0" id="Descomprimir">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h2 class="mb-5">
                - Descomprimir -
              </h2>

              <section class="grilla">
                <div class="contenido">
                  <p class="titulo">
                    <big>1.</big> Sobre el archivo seleccionado, da clic derecho con el mouse e indica el tipo de extracción a realizar.
                  </p>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <p class="font-weight-bold">
                    Se abrirá una ventana mostrando el proceso de extracción.
                  </p>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <p class="titulo">
                    <big>2.</big> Vuelve a dac clic derecho sobre el archivo zip que se extrajo y selecciona <strong>Extraer aquí.</strong>
                  </p>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <p class="font-weight-bold">
                    Se extraerá la carpeta contenida.
                  </p>
                </div>
              </section>

              <img src="@/assets/basededatos/descomprimir.gif" alt="" class="img-fluid mx-auto d-block mb-3">

              <p class="text-center">
                <strong>
                  Software para descomprimir archivos zip:
                </strong> <br />
                Winrar o 7-Zip (Windows) / Zipeg, Expander o RAR (Mac) <br />
                <strong>Winzip, no funciona</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row abrir py-5 mt-4 mr-0" id="Abrir">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h2 class="mb-5">
                - Abrir -
              </h2>

              <section class="grilla">
                <div class="contenido">
                  <p class="titulo">
                    <strong>Da doble clic</strong> sobre el archivo que quieras abrir.
                  </p>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <p>
                    <strong>Información de archivos "csv"</strong> <br />
                    Se puede ver en programas de hoja de cálculo como: Excel, Access, Calc Open Office, Smartsheet, entre otros.
                  </p>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <p class="titulo">
                    <strong>
                      Archivo LEEME.txt
                    </strong> <br />
                    Abre con el bloc de notas de Windows o Notas en Mac.
                  </p>
                </div>
              </section>

              <img src="@/assets/basededatos/abrir.gif" alt="" class="img-fluid mx-auto d-block mb-3">
            </div>
          </div>
        </div>
      </div>

      <div class="row consulta py-5 mt-4 mr-0" id="FormatoCeldas">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h1 class="text-center mb-5">
                Consulta
              </h1>
            </div>
            <div class="col-6">
              <h3 class="mb-2">
                - Formato de celdas -
              </h3>
              <p class="mb-5">
                Para una lectura correcta de las fechas y horas de corte, cambia el formato de celdas de las siguientes columnas a dd/mm/aaaa hh:mm
              </p>

              <ol class="descripcion">
                <li>
                  Selecciona las columnas.
                </li>
                <li>
                  Da <strong>clic derecho</strong> en el mouse.
                </li>
                <li>
                  Selecciona "Formato de celdas".
                </li>
                <li>
                  Elige el formato "dd/mm/aaaa hh:mm" y <strong>Acepta.</strong>
                </li>
              </ol>
            </div>
            <div class="col-6">
              <img src="@/assets/basededatos/consulta.gif" class="img-fluid">
            </div>
          </div>
        </div>
      </div>

      <div class="row interpretacion-datos py-5 mt-4 mr-0" id="InterpretacionBaseDatos">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h1 class="mb-5">
                - Interpretación de base de Datos -
              </h1>
              <p>
                A continuación te presentamos cuáles son los elementos que conforman el archivo de Base de Datos.
              </p>

              <div class="contenido mb-3">
                <h3>
                  Fila 1
                </h3>
                <p class="font-weight-bold pl-3">
                  Tipo de elección
                </p>
              </div>

              <div class="contenido mb-3">
                <div class="grilla">
                  <div>
                    <h3>
                      Fila 2
                    </h3>
                    <p class="pl-3">
                      <strong>Fecha y hora del corte de información</strong> con el formato <strong>DD/MM/AAAA HH:MM.</strong> <br />
                      Se especifica la hora en formato de 24 h, así como el huso horario del Centro de México.
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/basededatos/fila2.svg" alt="" class="img-fluid d-block mx-auto">
                  </div>
                </div>
              </div>

              <div class="contenido mb-3">
                <p>
                  Si deseas realizar una revisión detallada de las variables de la Base de Datos descarga <br />
                  el
                  <a href="#">
                    <u>
                      Manual Interpretación de la Base de Datos PREP, junio 2021.
                    </u>
                  </a>
                </p>
              </div>

              <div class="contenido mb-3">
                <h3>
                  Fila 3
                </h3>
                <p class="font-weight-bold pl-3">
                  Variables del resumen de la Base de Datos
                </p>

                <h3>
                  Fila 4
                </h3>
                <p class="font-weight-bold pl-3">
                  Resultado de las variables del resumen de la Base de Datos
                </p>
              </div>

              <div class="contenido mb-3">
                <div class="grilla">
                  <div>
                    <img src="@/assets/basededatos/fila4.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                  <div>
                    <ul>
                      <li>Actas Esperadas</li>
                      <li>Actas Registradas</li>
                      <li>Actas Fuera de catálogo</li>
                      <li>Actas Capturadas y porcentaje</li>
                      <li>Actas Contabilizadas y porcentaje</li>
                      <li>Porcentaje de Actas con inconsistencias</li>
                      <li>Actas no Contabilizadas</li>
                      <li>Lista Nominal de Actas Contabilizadas</li>
                      <li>Total de votos con Casillas Especiales</li>
                      <li>Total de votos sin Casillas Especiales</li>
                      <li>Porcentaje de Participación Ciudadana</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="contenido mb-3">
                <h3>
                  Fila 6
                </h3>
                <p class="font-weight-bold pl-3">
                  Indica las variables de lso resultados de votación, las cuales se pueden agrupar en:
                </p>
              </div>

              <div class="contenido mb-3">
                <div class="grilla">
                  <div>
                    <img src="@/assets/basededatos/fila6.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                  <div>
                    <ul>
                      <li>Identificación de las Actas</li>
                      <li>Totales derivados de la votación</li>
                      <li>Resultados de la votación</li>
                      <li>Estatus, traslado y seguridad de las Actas</li>
                      <li>Procesamiento de las Actas</li>
                      <li>Proceso de digitalización</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="contenido mb-3">
                <h3>
                  Fila 7
                </h3>
                <p class="font-weight-bold pl-3">
                  A partir de esta fila se indican los datos correspondientes a cada Acta
                </p>
              </div>
              <div class="contenido mb-3">
                <div class="grilla">
                  <div>
                    <img src="@/assets/basededatos/fila7.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row autenticidad py-5 mt-4 mr-0" id="Autenticidad">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <h1 class="mb-5">
                - Autenticidad -
              </h1>
              <p class="mb-5">
                Para validar la autenticidad de la imagen del Acta de tu casilla, puedes utilizar un software lector de código único de imagen de la siguiente forma.
              </p>

              <section class="grilla-3">
                <div class="contenido">
                  <h4>
                    Código HASH
                  </h4>

                  <ul>
                    <li>
                      Es una serie de caracteres únicos asociados al archivo de la imagen digitalizada del Acta de Escrutinio y Cómputo (AEC).
                    </li>
                  </ul>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4>
                    Vérifica
                  </h4>

                  <ul>
                    <li>
                      Si fue manipulada la imagen del AEC con algún editor de imágenes.
                    </li>
                    <li>
                      Intento de alteración del archivo.
                    </li>
                    <li>
                      Legitimidad de las imágens y de la información.
                    </li>
                  </ul>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4>
                    Valida
                  </h4>

                  <ul>
                    <li>
                      Descarga cualquier lector de Código SHA* (HASH 256) utilizado por el PREP para validar que el archivo no fue alterado
                    </li>
                  </ul>
                </div>
              </section>

              <img src="@/assets/basededatos/autenticidad-1.gif" alt="" class="img-fluid mx-auto d-block mb-5">

              <section class="grilla-3">
                <div class="contenido">
                  <h4>
                    Ubica
                  </h4>

                  <ul>
                    <li>
                      En el PREP, el Acta de la casilla que quieres verificar y descárgala en tu computadora.
                    </li>
                  </ul>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4>
                    Genera el Código HASH
                  </h4>

                  <ul>
                    <li>
                      Abre el lector de Código HASH 256 y sube el archivo de la imagen del Acta.
                    </li>
                    <li>
                      Sigue la secuencia: File-Select File.
                    </li>
                    <li>
                      Selecciona la opción SHA256.
                    </li>
                    <li>
                      Elige el archivo y presiona abrir.
                    </li>
                  </ul>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4>
                    Copia y pega
                  </h4>

                  <ul>
                    <li>
                      El código contenido en el archivo de resultados de la Base de Datos del PREP, en la columna SHA.
                    </li>
                  </ul>
                </div>
              </section>
            </div>

            <div class="col-6">
              <img src="@/assets/basededatos/autenticidad-2.svg" alt="" class="img-fluid d-block mx-auto p-5">
            </div>
            <div class="col-6">
              <img src="@/assets/basededatos/autenticidad-3.svg" alt="" class="img-fluid d-block mx-auto p-5">
            </div>

            <div class="col-12">
              <section class="grilla-2">
                <div class="contenido">
                  <h4>
                    Compara
                  </h4>

                  <ul>
                    <li>
                      El código de la imagen del Acta con el que te dio el lector de Código HASH 256 y comprueba su autenticidad.
                      <br />
                      <br />
                      <strong>
                        Todos los caracteres deben de coincidir, sin importar mayúsculas o minusculas.
                      </strong>
                    </li>
                  </ul>
                </div>
                <div class="icono">
                  <font-awesome-icon icon="angle-right" class="fa-8x" />
                </div>
                <div class="contenido">
                  <h4>
                    Identifica alteración
                  </h4>

                  <ul>
                    <li>
                      A través del Código HASH podrás identificar fácilmente si la imagen del Acta fue manipulada con algún software de edición de imágenes.
                    </li>
                  </ul>
                </div>
              </section>
            </div>

            <div class="col-6">
              <img src="@/assets/basededatos/autenticidad-4.svg" alt="" class="img-fluid d-block mx-auto p-5">
            </div>
            <div class="col-6">
              <img src="@/assets/basededatos/autenticidad-5.svg" alt="" class="img-fluid d-block mx-auto p-5">
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos jurídicos. <br /> Con base en la Ley Federal del Derecho de Autor queda prohíbida cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total o parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
        </p>
      </div>
    </cuerpo>
    <pie-de-pagina />
    <compartir />
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera'
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo'
import Compartir from '@/components/Compartir.vue'
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue'

export default {
  name: 'BaseDatos',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    PieDePagina
  }
}
</script>

<style lang="scss" scoped>
.video-explicativo {
  background-color: #962464;
}

.definicion {
  .texto-definicion {
    background-image: url('../assets/basededatos/fondo-definicion.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 0;

    p {
      max-width: 500px;
      margin:  0 auto;
    }
  }
}

.carpeta-archivos {
  background-color: #4a4473;
  color: #f2f2f2;

  ul.descripcion {
    list-style: initial;
    margin-left: 30px;

    li {
      margin-bottom: 45px;
    }
  }
}

.descargar, .descomprimir {
  .grilla {
    display: grid;
    grid-template-columns: 1fr 65px 1fr 65px 1fr 65px 1fr;
    gap: 15px;
    align-items: center;
    margin-bottom: 25px;

    .icono {
      svg {
        color: #34344E;
      }
    }
    .contenido {
      h4 {
        color: #34344E;
        line-height: 20px;
        margin-bottom: 30px;

        span {
          font-size: medium;
          margin-left: 10px;
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}

.descomprimir {
  background-color: #4a4473;
  color: #f2f2f2;
}

.abrir {
  .grilla {
    display: grid;
    grid-template-columns: 1fr 65px 1fr 65px 1fr;
    gap: 15px;
    align-items: center;
    margin-bottom: 25px;

    .icono {
      svg {
        color: #34344E;
      }
    }
    .contenido {
      h4 {
        color: #34344E;
        line-height: 20px;
        margin-bottom: 30px;

        span {
          font-size: medium;
          margin-left: 10px;
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}

.consulta {
  background-color: #962464;
  color: #f2f2f2;

  ol.descripcion {
    /* list-style: initial; */
    margin-left: 30px;

    li {
      margin-bottom: 45px;
    }
  }
}

.interpretacion-datos {
  h1, h3 {
    color: #962464;
  }

  p {
    color: #34344E;
  }
  .contenido {
    .grilla {
      display: grid;
      grid-template-columns: 1fr 300px;
      gap: 45px;
      align-items: center;

      ul {
        list-style: initial;
      }
    }
  }
}

.autenticidad {
  background-color: #962464;
  color: #f2f2f2;

  .grilla-3 {
    display: grid;
    grid-template-columns: 1fr 65px 1fr 65px 1fr;
    gap: 30px;

    ul {
      list-style: initial;

      li {
        margin-left: 15px;
        margin-bottom: 30px;
      }
    }
  }

  .grilla-2 {
    display: grid;
    grid-template-columns: 1fr 65px 1fr;
    gap: 30px;

    ul {
      list-style: initial;

      li {
        margin-left: 15px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>